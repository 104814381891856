export const CODE_ACCOUNT_EXISTS =
    'auth/account-exists-with-different-credential';
export const MSG_ACCOUNT_EXISTS = `Un compte avec cette adresse email existe déjà. Essayez d'utiliser une autre adresse email pour vous connecter.`;

export const CODE_WRONG_PASSWORD = 'auth/wrong-password';
export const MSG_WRONG_PASSWORD = `Le mot de passe que vous avez entré n'est pas valide.`;

export const CODE_USER_NOT_FOUND = 'auth/user-not-found';
export const MSG_USER_NOT_FOUND = `L'adresse email que vous avez entrée n'est pas valide.`;

export const CODE_USER_DISABLED = 'auth/user-disabled';
export const MSG_USER_DISABLED = `Le compte que vous avez entré a été désactivé. Veuillez nous contacter pour de plus amples informations.`;

export const CODE_INVALID_EMAIL = 'auth/invalid-email';
export const MSG_INVALID_EMAIL = `L'adresse email que vous avez entrée n'est pas bien formattée.`;

export const CODE_EMAIL_NOT_VERIFIED = 'auth/email-not-verified';
export const MSG_EMAIL_NOT_VERIFIED = `L'adresse email que vous avez entrée n'a pas été vérifiée.`;

export const CODE_INVALID_PASSWORD = 'auth/invalid-password';
export const MSG_INVALID_PASSWORD =
    'Le mot de passe que vous avez rentré est invalide';

export const CODE_WEAK_PASSWORD = 'auth/weak-password';
export const MSG_WEAK_PASSWORD =
    'Le mot de passe que vous avez rentré doit comporter au moins 6 caractères';

export const CODE_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use';
export const MSG_EMAIL_ALREADY_IN_USE =
    'Cett adresse email est déjà utilisée. Veuillez utiliser une autre adresse.';

export const CODE_TOO_MANY_REQUESTS = 'auth/too-many-requests';
export const MSG_TOO_MANY_REQUESTS =
    'Trop de requêtes soumises à la suite. Veuillez recommencez dans 5 minutes.';

export const CODE_ID_TOKEN_EXPIRED = 'auth/id-token-expired';
export const MSG_ID_TOKEN_EXPIRED = 'Le token id a expiré';

export const MSG_OTHER_ERROR = `Une erreur s'est produite. Veuillez réessayer l'opération ou nous contacter directement.`;
