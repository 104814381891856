/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Disclosure } from '@headlessui/react';
import { withRouter, Link } from 'react-router-dom';
import logo from 'assets/images/logo-small.webp';
import logoPro from 'assets/images/logo-small-pro.webp';
import * as ROUTES from 'constants/routingPaths';
import { compose } from 'recompose';
import withAuthUserContext from 'hoc/withAuthUserContext';
import withFirebase from 'hoc/withFirebase';
import { needsEmailVerification } from 'lib/user.utils';
import { classNames } from 'lib/utils';
import HeaderDefault from './HeaderDefault';
import HeaderAuthenticated from './HeaderAuthenticated';
import { PROFESSIONAL } from 'constants/userType';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWindowScrolling: false,
            currentPath: this.props.location.pathname,
        };
    }

    async componentDidMount() {
        window.onscroll = () => {
            this.setState({
                isWindowScrolling: window.scrollY > 0,
            });
        };
        this.setState({ currentPath: this.props.location.pathname });
    }

    getLogo = isPro => {
        return (
            <Link to={isPro ? ROUTES.HOME_PRO : ROUTES.HOME}>
                <img
                    className="block h-7 w-auto mt-2"
                    src={isPro ? logoPro : logo}
                    alt="Immo Data"
                />
            </Link>
        );
    };

    render() {
        const { isAuthLoading, authUser, userType } = this.props.context;

        if (
            this.props.location.pathname === ROUTES.LOGIN ||
            this.props.location.pathname === ROUTES.SIGN_UP ||
            this.props.location.pathname === ROUTES.PASSWORD_FORGET ||
            this.props.location.pathname === ROUTES.ACTION
        )
            return null;

        // We check if the user is on a pro page
        const isProPage =
            this.props.location.pathname.startsWith(ROUTES.HOME_PRO + '/') ||
            this.props.location.pathname === ROUTES.HOME_PRO;

        const logo = this.getLogo(isProPage || userType === PROFESSIONAL);

        const isTransactionExplorer = this.props.location.pathname.startsWith(
            ROUTES.TRANSACTION_EXPLORER
        );
        const isAccount = this.props.location.pathname.startsWith(
            ROUTES.ACCOUNT
        );
        const isAccessManagement = this.props.location.pathname.startsWith(
            ROUTES.ACCESS_MANAGEMENT
        );
        const isPropertyExplorer = this.props.location.pathname.startsWith(
            ROUTES.PROPERTY_EXPLORER
        );
        const isShowcase = this.props.location.pathname.startsWith(
            ROUTES.SHOWCASE
        );
        const isMarketAnalyser = this.props.location.pathname.startsWith(
            ROUTES.MARKET_ANALYSER_ROOT
        );
        const isOnlinePropertyReport = this.props.location.pathname.startsWith(
            '/rapport/'
        );
        const isWelcome = this.props.location.pathname.startsWith(
            ROUTES.WELCOME
        );
        let isMarketExplorer = this.props.location.pathname.startsWith(
            ROUTES.MARKET_EXPLORER
        );

        const isValuationWidget = this.props.location.pathname.startsWith(
            ROUTES.VALUATION_WIDGET
        );

        const isPayment = this.props.location.pathname.startsWith(
            ROUTES.PAYMENT_CHECKOUT
        );
        if (isPayment) return null;

        if (
            (needsEmailVerification(authUser) &&
                (isValuationWidget ||
                    isPropertyExplorer ||
                    isTransactionExplorer ||
                    isAccount ||
                    isAccessManagement ||
                    isWelcome ||
                    isMarketAnalyser)) ||
            // If online Report we hide the header to display a white label header
            isOnlinePropertyReport
        )
            return null;

        if (isMarketExplorer) {
            const urlSplit = this.props.location.pathname.split('/');
            isMarketExplorer = urlSplit.length > 3 ? urlSplit[4] !== '' : false;
        }

        const { isWindowScrolling } = this.state;
        return (
            <>
                <Disclosure
                    as="nav"
                    className={classNames(
                        'bg-white sticky w-full z-40 top-0',
                        isWindowScrolling &&
                            !isPropertyExplorer &&
                            !isMarketAnalyser
                            ? 'shadow-md z-30'
                            : '',
                        isValuationWidget ||
                            isPropertyExplorer ||
                            isTransactionExplorer ||
                            isAccount ||
                            isAccessManagement ||
                            isMarketAnalyser ||
                            isShowcase
                            ? 'border-b border-gray-200'
                            : ''
                    )}
                >
                    {({ open }) =>
                        isAuthLoading ? (
                            <div className="mx-auto px-4 sm:px-6 lg:px-8 xl:px-8">
                                <div className="flex flex-wrap justify-between gap-y-3 min-h-[64px]">
                                    <div className="flex-shrink-0 items-center sm:gap-6 lg:gap-6 xl:gap-6 flex">
                                        {logo}
                                    </div>
                                    <div className="skeleton hidden sm:flex sm:items-center sm:gap-6">
                                        <div className="w-36 h-4 rounded-md" />
                                        <div className="w-8  h-8 rounded-full" />
                                    </div>
                                </div>
                            </div>
                        ) : !authUser ? (
                            <HeaderDefault
                                open={open}
                                isProPage={isProPage}
                                isWindowScrolling={isWindowScrolling}
                                isMarketExplorer={isMarketExplorer}
                                logo={logo}
                            />
                        ) : (
                            <HeaderAuthenticated
                                open={open}
                                isProPage={isProPage}
                                logo={logo}
                            />
                        )
                    }
                </Disclosure>
            </>
        );
    }
}

export default compose(withRouter, withAuthUserContext, withFirebase)(Header);
