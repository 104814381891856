import { initializeApp } from 'firebase/app';
import {
    initializeAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail,
    sendEmailVerification,
    updatePassword,
    checkActionCode,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    reauthenticateWithCredential,
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider,
    onAuthStateChanged,
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserPopupRedirectResolver,
    getAdditionalUserInfo,
} from 'firebase/auth';
//
import { setAuthToken } from './request';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor() {
        const app = initializeApp(config);

        // /* Firebase APIs */
        this.auth = initializeAuth(app, {
            persistence: [indexedDBLocalPersistence, browserLocalPersistence],
            popupRedirectResolver: browserPopupRedirectResolver,
        });

        /* Social Sign In Method Provider */
        this.googleProvider = new GoogleAuthProvider();
        this.facebookProvider = new FacebookAuthProvider();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = async (email, password) =>
        await createUserWithEmailAndPassword(this.auth, email, password);

    doSignInWithEmailAndPassword = async (email, password) =>
        await signInWithEmailAndPassword(this.auth, email, password);

    doSignInWithGoogle = async () => {
        const res = await signInWithPopup(this.auth, this.googleProvider);
        const { isNewUser } = getAdditionalUserInfo(res);
        return { ...res, isNewUser };
    };

    doSignInWithFacebook = async () => {
        const res = await signInWithPopup(this.auth, this.facebookProvider);
        const { isNewUser } = getAdditionalUserInfo(res);
        return { ...res, isNewUser };
    };

    doSignOut = async () => await signOut(this.auth);

    doPasswordReset = async email =>
        await sendPasswordResetEmail(this.auth, email);

    doSendEmailVerification = async continueUrl => {
        const params = continueUrl
            ? {
                  url: process.env.REACT_APP_BASE_URL + continueUrl,
              }
            : null;

        await sendEmailVerification(this.auth.currentUser, params);
        return;
    };

    doPasswordUpdate = async password =>
        await updatePassword(this.auth.currentUser, password);

    doCheckActionCode = async code => await checkActionCode(this.auth, code);

    doApplyActionCode = async code => await applyActionCode(this.auth, code);

    doVerifyPasswordResetCode = async code =>
        await verifyPasswordResetCode(this.auth, code);

    doConfirmPasswordReset = async (code, newPassword) =>
        await confirmPasswordReset(this.auth, code, newPassword);

    doReauthenticate = async currentPassword => {
        const user = this.auth.currentUser;
        const cred = EmailAuthProvider.credential(user.email, currentPassword);
        return await reauthenticateWithCredential(user, cred);
    };

    getCurrentUser = () => this.auth.currentUser;

    getCurrentUserClaims = async () => {
        const idToken = await this.auth.currentUser.getIdToken(true);
        setAuthToken(idToken);
        const idTokenResult = await this.auth.currentUser.getIdTokenResult();
        return idTokenResult.claims;
    };

    setUserToken = async authUser => {
        const user = authUser || this.auth.currentUser;
        const idToken = await user.getIdToken(true);
        setAuthToken(idToken);
        return;
    };

    reloadUser = async () => {
        const currentUser = this.auth.currentUser;
        if (currentUser) {
            await currentUser.reload();
            await this.setUserToken();
        }
        return this.auth.currentUser;
    };

    onAuthUserListener = (next, fallback) =>
        onAuthStateChanged(this.auth, async authUser => {
            // User is signed in
            if (authUser) {
                try {
                    await this.setUserToken(authUser);
                    next(authUser);
                    if (process.env.NODE_ENV !== 'development') {
                        if (window.posthog)
                            window.posthog.identify(authUser.uid);
                    }
                } catch (e) {
                    fallback();
                }
            } else {
                fallback();
            }
        });
}

export default Firebase;
