import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Menu } from '@headlessui/react';
import { ADMIN } from 'constants/accessManagement';
import * as ROUTES from 'constants/routingPaths';
import { DEFAULT_COLORS } from 'constants/colors';
import { BUSINESS, SOLO } from 'constants/planType';
import { INDIVIDUAL } from 'constants/userType';
import { classNames } from 'lib/utils';
import DropdownContainer from 'components/Containers/DropdownContainer';
import withAuthUserContext from 'hoc/withAuthUserContext';

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProfileImgWithError: false,
        };
    }
    handleErrorLoadingProfileImg = e => {
        this.setState({ isProfileImgWithError: true });
    };
    render() {
        const { handleLogout } = this.props;
        const {
            authUser,
            userRole,
            planType,
            parentPlanType,
            hasSubscription,
            userType,
        } = this.props.context;
        const { isProfileImgWithError } = this.state;
        const photoURL = authUser ? authUser.photoURL : null;
        return (
            <DropdownContainer
                button={
                    <div className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                        {photoURL &&
                        photoURL !== '' &&
                        !isProfileImgWithError ? (
                            <img
                                className="h-8 w-8 rounded-full"
                                onError={this.handleErrorLoadingProfileImg}
                                src={photoURL}
                                alt="Account"
                            />
                        ) : (
                            <svg
                                width="32px"
                                height="32px"
                                viewBox="0 0 510 510"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="accountIcon">
                                    <path
                                        d="M255,0 C114.75,0 0,114.75 0,255 C0,395.25 114.75,510 255,510 C395.25,510 510,395.25 510,255 C510,114.75 395.25,0 255,0 Z M255,76.5 C298.35,76.5 331.5,109.65 331.5,153 C331.5,196.35 298.35,229.5 255,229.5 C211.65,229.5 178.5,196.35 178.5,153 C178.5,109.65 211.65,76.5 255,76.5 Z M255,438.6 C191.25,438.6 135.15,405.451 102,357 C102,306 204,277.95 255,277.95 C306,277.95 408,306 408,357 C374.85,405.45 318.75,438.6 255,438.6 Z"
                                        fill={DEFAULT_COLORS().primary500}
                                    />
                                </g>
                            </svg>
                        )}
                    </div>
                }
                className={'py-1'}
            >
                <Menu.Item>
                    {({ active }) => (
                        <Link
                            to={ROUTES.PROFILE}
                            className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                            )}
                        >
                            Mon Compte
                        </Link>
                    )}
                </Menu.Item>
                {userRole === ADMIN &&
                userType !== INDIVIDUAL &&
                (parentPlanType === null || hasSubscription) &&
                (planType === BUSINESS || planType === SOLO) ? (
                    <Menu.Item>
                        {({ active }) => (
                            <Link
                                to={ROUTES.ACCESS_MANAGEMENT}
                                className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                Gestion des utilisateurs
                            </Link>
                        )}
                    </Menu.Item>
                ) : null}
                <Menu.Item>
                    {({ active }) => (
                        <a
                            href="/#"
                            onClick={handleLogout}
                            className={classNames(
                                active ? 'bg-red-50' : '',
                                'block px-4 py-2 text-sm text-red-500'
                            )}
                        >
                            Déconnexion
                        </a>
                    )}
                </Menu.Item>
            </DropdownContainer>
        );
    }
}

export default compose(withAuthUserContext)(ProfileMenu);
