import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import Firebase from 'lib/firebase';
import FirebaseContext from 'context/FirebaseContext';
import { createBrowserHistory } from 'history';

import './index.css';

const history = createBrowserHistory();

render(
    <FirebaseContext.Provider value={new Firebase()}>
        <Router history={history}>
            <App />
        </Router>
    </FirebaseContext.Provider>,
    document.getElementById('app-root')
);

serviceWorker.unregister();
