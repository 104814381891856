import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Notification from 'components/Notification/Notification';
import * as ROUTES from 'constants/routingPaths';
import {
    MSG_OTHER_ERROR,
    CODE_ID_TOKEN_EXPIRED,
    CODE_EMAIL_NOT_VERIFIED,
    MSG_EMAIL_NOT_VERIFIED,
} from 'constants/authErrors';
import withFirebase from 'hoc/withFirebase';

const withNotification = Component => {
    class WithNotification extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                message: null,
                type: null,
                timestamp: null,
            };
        }

        handleError = async error => {
            const response = error && error.response;
            const errorData = response && response.data;
            const message = errorData ? errorData.message : MSG_OTHER_ERROR;
            const code = errorData ? errorData.code || errorData.key : null;

            // If if it's a 401 error, i.e. authorisation issue the user will be logged out
            if (
                response &&
                (response.status === 401 || response.status === 403)
            ) {
                // If token has expired we need to refresh it
                if (code === CODE_ID_TOKEN_EXPIRED) {
                    window.location.reload();
                    return;
                }
                const { firebase, history } = this.props;

                // If email is not verified
                if (code === CODE_EMAIL_NOT_VERIFIED) {
                    this.setState({
                        message: MSG_EMAIL_NOT_VERIFIED,
                        type: 'error',
                        timestamp: Date.now(),
                    });
                    return;
                }

                // otherwise we log out the user (for example if account is disabled, role has changed...)
                await firebase.doSignOut();
                history.push(ROUTES.LOGIN);
                return;
            }
            // hack to avoid displaying an error message when bounds error (TO BE REMOVED LATER ON when there is no more bounds limit)
            if (code === 'bounds') return;
            this.setState({ message, type: 'error', timestamp: Date.now() });
        };

        handleSuccess = async message => {
            this.setState({ message, type: 'success', timestamp: Date.now() });
        };

        render() {
            const { message, type, timestamp } = this.state;
            return (
                <React.Fragment>
                    <Notification
                        message={message}
                        type={type}
                        timestamp={timestamp}
                    />
                    <Component
                        {...this.props}
                        handleError={this.handleError}
                        handleSuccess={this.handleSuccess}
                    />
                </React.Fragment>
            );
        }
    }

    return compose(withRouter, withFirebase)(WithNotification);
};

export default withNotification;
