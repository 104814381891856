import React from 'react';
import { getUserPermissionLevel } from 'services/userApi';
import AuthUserContext from 'context/AuthUserContext';
import withNotification from 'hoc/withNotification';
import { setLastConnectionDate } from 'services/crmApi';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isAuthLoading: true,
                authUser: null,
                planType: null,
                planStatus: null,
                userRole: null,
                hasSubscription: false,
                parentPlanType: null,
                hasCustomerAccount: false,
                userType: null,
                setAuthLoading: isAuthLoading =>
                    this.setState({ isAuthLoading }),
                setUser: async (
                    authUser,
                    userRole,
                    planType,
                    planStatus,
                    hasSubscription,
                    parentPlanType,
                    hasCustomerAccount,
                    userType
                ) => {
                    this.setState({
                        authUser,
                        userRole,
                        planType,
                        planStatus,
                        hasSubscription,
                        parentPlanType,
                        isAuthLoading: false,
                        hasCustomerAccount,
                        userType,
                    });
                },
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                async authUser => {
                    try {
                        const {
                            planType,
                            userRole,
                            planStatus,
                            hasSubscription,
                            parentPlanType,
                            hasCustomerAccount,
                            userType,
                        } = authUser ? await getUserPermissionLevel() : null;
                        if (
                            authUser &&
                            sessionStorage.getItem('sessionOpen') === null
                        ) {
                            sessionStorage.setItem('sessionOpen', 1);
                            setLastConnectionDate();
                        }

                        this.setState({
                            authUser,
                            planType,
                            userRole,
                            planStatus,
                            hasSubscription,
                            parentPlanType,
                            hasCustomerAccount,
                            userType,
                            isAuthLoading: false,
                        });
                    } catch (e) {
                        this.setState(
                            {
                                authUser,
                                planType: null,
                                userRole: null,
                                planStatus: null,
                                hasSubscription: false,
                                parentPlanType: null,
                                hasCustomerAccount: false,
                                isAuthLoading: false,
                                userType: null,
                            },
                            () => this.props.handleError(e)
                        );
                    }
                },
                () => {
                    this.setState({
                        authUser: null,
                        planType: null,
                        userRole: null,
                        planStatus: null,
                        hasSubscription: false,
                        parentPlanType: null,
                        hasCustomerAccount: false,
                        isAuthLoading: false,
                        userType: null,
                    });
                }
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withNotification(WithAuthentication);
};

export default withAuthentication;
