import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ADMIN, SUPERVISOR } from 'constants/accessManagement';
import { INDIVIDUAL, PROFESSIONAL } from 'constants/userType';

export const needsEmailVerification = authUser =>
    authUser &&
    !authUser.emailVerified &&
    //Check if the account has been created more than 48 hours ago
    (new Date().getTime() -
        new Date(authUser.metadata.creationTime).getTime()) /
        3600000 >
        48 &&
    authUser.providerData
        .map(provider => provider.providerId)
        .includes('password');

export const getRoleLabel = role => {
    if (role === ADMIN) return 'Administrateur';
    if (role === SUPERVISOR) return 'Superviseur';
    return 'Utilisateur';
};

export const getUserTypeFeatures = currentUserType => {
    if (currentUserType.value === INDIVIDUAL) {
        return [
            {
                icon: light('bell'),
                title: 'Alertes nouvelles ventes.',
                description:
                    'Soyez prévenu dès que de nouvelles ventes sont publiées.',
            },
            {
                icon: light('house-circle-check'),
                title: "Aide à l'achat et à la vente.",
                description:
                    "Bénéficiez de services d'estimation, d'analyse et de financement pour votre projet immobilier.",
            },
            {
                icon: light('magnifying-glass-chart'),
                title: 'Analyses immobilières.',
                description:
                    "Analysez facilement n'importe quel bien immobilier et n'importe quel secteur géographique.",
            },
        ];
    }
    if (currentUserType.value === PROFESSIONAL) {
        return [
            {
                icon: light('megaphone'),
                title: 'Visibilité gratuite.',
                description:
                    'Créez votre vitrine digitale pour présenter gratuitement vos services sur Immo Data.',
            },
            {
                icon: light('handshake'),
                title: 'Mandats supplémentaires.',
                description:
                    'Obtenez plus de mandats grâce à des outils gratuits de marketing et de prospection avancés.',
            },
            {
                icon: light('file-chart-column'),
                title: 'Estimation et Rapports.',
                description:
                    'Réalisez des analyses professionnelles et générez vos Avis de valeur.',
            },
        ];
    }
    return null;
};
