export const INDIVIDUAL = 1;
export const PROFESSIONAL = 2;

export const USER_TYPES_LIST = [
    {
        label: 'Particulier',
        value: INDIVIDUAL,
        color: 'primary',
    },
    {
        label: 'Professionnel',
        value: PROFESSIONAL,
        color: 'pro',
    },
];
