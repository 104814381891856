export const DEFAULT_COLORS = () => {
    return {
        primaryLightest: document.documentElement.style.getPropertyValue(
            '--primary-lightest-color'
        ),
        primaryLighter: document.documentElement.style.getPropertyValue(
            '--primary-lighter-color'
        ),
        primaryLight: document.documentElement.style.getPropertyValue(
            '--primary-light-color'
        ),
        primary: document.documentElement.style.getPropertyValue(
            '--primary-color'
        ),
        primaryDark: document.documentElement.style.getPropertyValue(
            '--primary-dark-color'
        ),
        primaryDarker: document.documentElement.style.getPropertyValue(
            '--primary-darker-color'
        ),
        overPrimaryBg: document.documentElement.style.getPropertyValue(
            '--over-primary-bg'
        ),
        primaryText: document.documentElement.style.getPropertyValue(
            '--primary-text'
        ),

        primary50: '#EDF2FF',
        primary100: '#E0E7FF',
        primary200: '#C7D2FE',
        primary300: '#A5B4FD',
        primary400: '#818CF8',
        primary500: '#6466F1',
        primary600: '#5046E5',
        primary700: '#4338CA',
        primary800: '#3730A3',
        primary900: '#1F204C',

        gray50: '#F9FAFB',
        gray100: '#F3F4F6',
        gray200: '#E5E7EB',
        gray300: '#D1D5DC',
        gray400: '#9DA4B1',
        gray500: '#6D7481',
        gray600: '#4F5A69',
        gray700: '#3B4556',
        gray800: '#222C3B',
        gray900: '#161C2C',

        green50: '#EFFBE9',
        green100: '#E1F8D8',
        green200: '#C1F1AC',
        green300: '#A3EB85',
        green400: '#82E359',
        green500: '#65DD32',
        green600: '#4CB81F',
        green700: '#3A8C17',
        green800: '#265C0F',
        green900: '#143008',

        red50: '#FEF2F2',
        red100: '#FEE2E1',
        red200: '#FFB8B8',
        red300: '#FF9494',
        red400: '#F87171',
        red500: '#EF4444',
        red600: '#DC2625',
        red700: '#B91C1B',
        red800: '#850000',
        red900: '#420000',

        yellow50: '#FFFAEB',
        yellow100: '#FFF4D1',
        yellow200: '#FFE9A8',
        yellow300: '#FFDE7A',
        yellow400: '#FFD24D',
        yellow500: '#FFC720',
        yellow600: '#E6AC00',
        yellow700: '#AD8200',
        yellow800: '#755800',
        yellow900: '#382A00',

        pro50: '#FFF0F3',
        pro100: '#FFDCE3',
        pro200: '#FEBDCA',
        pro300: '#FE9AAE',
        pro400: '#FD7792',
        pro500: '#FD5577',
        pro600: '#FC1241',
        pro700: '#CA022A',
        pro800: '#88021C',
        pro900: '#42010E',

        gradient1: '#8EDD25',
        gradient2: '#CEE629',
        gradient3: '#EECC2D',
        gradient4: '#F79831',
        gradient5: '#FF6135',

        white: '#FFFFFF',
    };
};
