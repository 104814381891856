import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { classNames } from 'lib/utils';

const classes = {
    base: 'rounded-full',
    displayStyle: {
        base: 'bg-gray-400/50 hover:bg-gray-600/50 text-white p-1',
        light: 'bg-transparent text-gray-400 hover:text-gray-700', //No background color
    },
    size: {
        sm: 'w-3 h-3',
        base: 'w-4 h-4',
        lg: 'w-5 h-5',
    },
};

class CloseIcon extends Component {
    render() {
        const { displayStyle, size, onClick } = this.props;
        return (
            <div
                className="cursor-pointer flex items-center"
                onClick={e => onClick()}
            >
                <FontAwesomeIcon
                    icon={regular('times')}
                    className={classNames(
                        classes.base,
                        classes.size[size],
                        classes.displayStyle[displayStyle]
                    )}
                />
            </div>
        );
    }
}

CloseIcon.defaultProps = {
    displayStyle: 'base',
    size: 'base',
};
export default CloseIcon;
