import axios from 'axios';

axios.defaults.baseURL =
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:3069';
axios.defaults.headers.common = {
    Accept: 'application/json, application/xml, text/play, text/html, *.*',
    'Content-Type': 'application/json',
};

export const setAuthToken = token => {
    axios.defaults.headers.common.Authorization = token;
};

export const setReportToken = token => {
    axios.defaults.headers['report-token'] = token;
};

export default axios;
