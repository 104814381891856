import React from 'react';
import AuthUserContext from 'context/AuthUserContext';

const withAuthUserContext = Component => {
    return function WrapperComponent(props) {
        return (
            <AuthUserContext.Consumer>
                {state => <Component {...props} context={state} />}
            </AuthUserContext.Consumer>
        );
    };
};

export default withAuthUserContext;
