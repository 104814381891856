import request from '../lib/request';

export const setNps = body =>
    request.post(`api/crm/nps`, body).then(({ data }) => data);

export const setNpsComment = body =>
    request.post(`api/crm/nps-comment`, body).then(({ data }) => data);

export const addToLists = body =>
    request.post(`api/crm/addtoLists`, body).then(({ data }) => data);

export const setCancellationReason = body =>
    request.post(`api/crm/cancellation-reason`, body).then(({ data }) => data);

export const setHasDownloadedPDF = () =>
    request.post(`api/crm/has-downloaded-pdf`).then(({ data }) => data);

export const setLastConnectionDate = () =>
    request.post(`api/crm/last-connection-date`).then(({ data }) => data);

export const setWantEmbeddedDvf = body =>
    request.post(`api/crm/want-embedded-dvf`, body).then(({ data }) => data);
