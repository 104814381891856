import React from 'react';
import { Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from 'components/CloseIcon/CloseIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    componentDidMount() {
        const { message, timestamp } = this.props;
        if (message && timestamp) this.setShow(true);
    }

    componentDidUpdate(prevProps) {
        const { message, timestamp } = this.props;
        if (message && timestamp !== prevProps.timestamp) this.setShow(true);
    }

    setShow = show => {
        this.setState({ show });
        const { type } = this.props;
        if (show) {
            // Auto hide notification after 10 seconds for errors and 3 seconds otherwise
            setTimeout(
                () => {
                    this.setState({ show: false });
                },
                type === 'error' ? 10000 : 3000
            );
        }
    };

    getLabel = () => {
        const { type } = this.props;
        if (type === 'error') return "Une erreur s'est produite.";
        if (type === 'success') return 'Tout est en ordre!';
    };

    getIcon = () => {
        const { type } = this.props;
        if (type === 'error')
            return (
                <FontAwesomeIcon
                    icon={light('circle-exclamation')}
                    className="text-red-600 h-5 w-5"
                />
            );
        if (type === 'success')
            return (
                <FontAwesomeIcon
                    icon={light('circle-check')}
                    className="text-green-600 h-5 w-5"
                />
            );
    };

    render() {
        const { message } = this.props;
        const { show } = this.state;
        return message ? (
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex px-4 py-6 items-start sm:p-6 z-50"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={React.Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="-translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0 m-0.5">
                                        {this.getIcon()}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5 text-left">
                                        <p className="text-sm font-medium text-gray-900">
                                            {this.getLabel()}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {message}
                                        </p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <CloseIcon
                                            size="base"
                                            displayStyle="light"
                                            onClick={e => this.setShow(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        ) : null;
    }
}

export default Notification;
