import { Popover, Transition } from '@headlessui/react';
import React, { Component, Fragment } from 'react';
import { classNames } from 'lib/utils';

const classes = {
    position: {
        left: 'origin-top-right right-0 ',
        right: 'origin-top-left left-0 ',
    },
};

class PopoverContainer extends Component {
    render() {
        const { width, className, position } = this.props;

        return (
            <Popover className="relative h-full">
                {({ open }) => (
                    <>
                        <Popover.Button className="h-full">
                            {this.props.button}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className={classNames(
                                    classes.position[position],
                                    'absolute mt-2 z-30 transform px-0',
                                    width ? width : 'w-80',
                                    className ? className : ''
                                )}
                            >
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5  bg-white">
                                    {this.props.children}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        );
    }
}
PopoverContainer.defaultProps = {
    position: 'left',
};

export default PopoverContainer;
