/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Disclosure } from '@headlessui/react';
import { withRouter, Link } from 'react-router-dom';
import * as ROUTES from 'constants/routingPaths';
import { ADMIN } from 'constants/accessManagement';
import { FREE } from 'constants/planType';
import { compose } from 'recompose';
import withAuthUserContext from 'hoc/withAuthUserContext';
import withFirebase from 'hoc/withFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProductDropdown from './ProductDropdown/ProductDropdown';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import Badge from 'components/Badge/Badge';
import { INDIVIDUAL } from 'constants/userType';
import MobileMenuAuthenticated from './MobileMenu/MobileMenuAuthenticated';
import { classNames } from 'lib/utils';

class HeaderAuthenticated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: this.props.location.pathname,
        };
    }

    async componentDidMount() {
        this.setState({ currentPath: this.props.location.pathname });
    }

    logout = async e => {
        try {
            await this.props.firebase.doSignOut();
            this.props.history.push(ROUTES.HOME);
        } catch (error) {
            this.props.history.push(ROUTES.HOME);
        }
    };

    getProductItems = () => {
        const { userRole, userType } = this.props.context;
        const items = [
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Explorateur des ventes DVF',
                description:
                    'Consultez toutes les ventes immobilières réalisées en France.',
                icon: thin('map-location-dot'),
                url: ROUTES.TRANSACTION_EXPLORER_SEARCH,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.TRANSACTION_EXPLORER_SEARCH
                ),
            },
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Données de marché',
                description:
                    'Analysez en détail le marché immobilier de votre secteur géographique.',
                icon: thin('chart-mixed'),
                url: ROUTES.MARKET_ANALYSER_ROOT,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.MARKET_ANALYSER_ROOT
                ),
            },
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Analyseur de biens',
                description:
                    "Analysez n'importe quel bien immobilier, estimez sa valeur, générez des rapports détaillés.",
                icon: thin('magnifying-glass-location'),
                url: ROUTES.PROPERTY_EXPLORER_SEARCH,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PROPERTY_EXPLORER_SEARCH
                ),
            },
        ];
        if (userType !== INDIVIDUAL) {
            items.push({
                category: 'Prospecter',
                subCategory: 'Contacts',
                title: 'Vos prospects',
                description:
                    'Gérez tous vos prospects dans votre tableau de bord.',
                icon: thin('address-card'),
                url: ROUTES.LEADS,
                isHighlighted: window.location.pathname.includes(ROUTES.LEADS),
            });
        }

        if (userRole === ADMIN && userType !== INDIVIDUAL) {
            items.push(
                {
                    category: 'Prospecter',
                    subCategory: 'Contacts',
                    title: 'Prospects à proximité',
                    description:
                        'Accédez à la liste des prospects dans votre secteur géographique.',
                    icon: thin('people-group'),
                    url: ROUTES.PROSPECTS,
                    isHighlighted: window.location.pathname.includes(
                        ROUTES.PROSPECTS
                    ),
                },
                {
                    category: 'Prospecter',
                    subCategory: 'Outils',
                    title: 'Vitrine immobilière',
                    description:
                        'Présentez vos services pour être contacté par les vendeurs qui viennent sur Immo Data.',
                    icon: thin('store'),
                    url: ROUTES.SHOWCASE,
                    isHighlighted: window.location.pathname.includes(
                        ROUTES.SHOWCASE
                    ),
                },
                {
                    category: 'Prospecter',
                    subCategory: 'Outils',
                    title: "Module d'estimation",
                    description:
                        "Installez un module d'estimation sur votre site et récupérez les coordonnées de vos visiteurs.",
                    icon: thin('magnet'),
                    url: ROUTES.VALUATION_WIDGET,
                    isHighlighted: window.location.pathname.includes(
                        ROUTES.VALUATION_WIDGET
                    ),
                }
            );
        }

        return items;
    };

    render() {
        const { userType, planType } = this.props.context;
        const { isProPage, open, logo } = this.props;

        const items = this.getProductItems();

        return (
            <>
                <div className="mx-auto px-4 sm:px-6 lg:px-8 xl:px-8">
                    <div className="flex flex-wrap justify-between gap-y-3 min-h-[64px]">
                        {/* LEFT MENU */}
                        <div className="flex-shrink-0 items-center sm:gap-6 lg:gap-6 xl:gap-6 flex">
                            {logo}
                        </div>
                        <div className="hidden lg:flex lg:items-center lg:gap-6">
                            {planType === FREE && (
                                <Link to={ROUTES.PRICING}>
                                    <Badge
                                        color="green"
                                        label="Passer au plan supérieur"
                                        padding="lg"
                                        icon={light('up-right-from-square')}
                                    />
                                </Link>
                            )}
                            {userType === INDIVIDUAL && (
                                <>
                                    <Link
                                        to={ROUTES.VALUATION_ADDRESS}
                                        className={classNames(
                                            'hidden lg:inline-flex text-gray-900 h-full  items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                            window.location.pathname.includes(
                                                ROUTES.VALUATION_ADDRESS
                                            )
                                                ? 'border-primary-500'
                                                : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                                        )}
                                    >
                                        Estimer un bien
                                    </Link>
                                    <Link
                                        to={
                                            ROUTES.AGENCIES_AND_AGENTS_DIRECTORY
                                        }
                                        className={classNames(
                                            'hidden lg:inline-flex text-gray-900 h-full  items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                            window.location.pathname.includes(
                                                ROUTES.AGENCIES_AND_AGENTS_DIRECTORY
                                            ) ||
                                                window.location.pathname.includes(
                                                    ROUTES.SHOWCASE
                                                )
                                                ? 'border-primary-500'
                                                : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                                        )}
                                    >
                                        Trouver un agent
                                    </Link>
                                </>
                            )}
                            {userType !== INDIVIDUAL && (
                                <ProductDropdown
                                    label="Prospecter"
                                    items={items.filter(
                                        item => item.category === 'Prospecter'
                                    )}
                                />
                            )}
                            <ProductDropdown
                                label="Analyser"
                                items={items.filter(
                                    item => item.category === 'Analyser'
                                )}
                            />
                            <ProfileMenu handleLogout={this.logout} />
                        </div>
                        <div className="-mr-2 flex items-center lg:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                                aria-label="menu"
                            >
                                {open ? (
                                    <FontAwesomeIcon
                                        icon={light('xmark')}
                                        className="block h-6 w-6"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={light('bars')}
                                        className="block h-6 w-6"
                                        aria-hidden="true"
                                    />
                                )}
                            </Disclosure.Button>
                        </div>
                    </div>
                </div>
                <MobileMenuAuthenticated
                    items={items}
                    handleLogout={this.logout}
                    isProPage={isProPage}
                />
            </>
        );
    }
}

export default compose(
    withRouter,
    withAuthUserContext,
    withFirebase
)(HeaderAuthenticated);
