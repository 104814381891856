import { Menu, Transition } from '@headlessui/react';
import { classNames } from 'lib/utils';
import React, { Component, Fragment } from 'react';

class DropdownContainer extends Component {
    render() {
        const { button, children, width, disabled, className } = this.props;
        return (
            <Menu as="div" className="relative h-full">
                <Menu.Button disabled={disabled} className="h-full">
                    {button}
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className={classNames(
                            'absolute right-0 mt-2 z-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                            width ? width : 'w-48',
                            className
                        )}
                    >
                        {children}
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    }
}

DropdownContainer.defaultProps = {
    button: 'button',
    children: <Menu.Item>Waiting for content</Menu.Item>,
    width: 'w-48',
    disabled: false,
    className: '',
};
export default DropdownContainer;
