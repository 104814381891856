import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import PopoverContainer from 'components/Containers/PopoverContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { classNames } from 'lib/utils';

class ProductDropdown extends Component {
    getSubCategoryGroup = items => {
        const groupedBySubCategory = {};

        // We group dropdown items by subcategories
        items.forEach(item => {
            if (!groupedBySubCategory[item.subCategory]) {
                groupedBySubCategory[item.subCategory] = [];
            }
            groupedBySubCategory[item.subCategory].push(item);
        });
        return groupedBySubCategory;
    };

    render() {
        const { label, items, position } = this.props;
        const groupedBySubCategory = this.getSubCategoryGroup(items);

        return (
            <PopoverContainer
                button={
                    <span
                        className={classNames(
                            'text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-full',
                            items.filter(item =>
                                window.location.pathname.includes(item.url)
                            ).length > 0
                                ? 'border-primary-500'
                                : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                        )}
                    >
                        {label}
                        <FontAwesomeIcon
                            icon={light('chevron-down')}
                            className="block text-gray-900 ml-2"
                            aria-hidden="true"
                        />
                    </span>
                }
                width="w-96"
                position={position}
            >
                <div className="relative grid gap-3 lg:grid-cols-1 p-5">
                    {Object.keys(groupedBySubCategory).map(
                        (subCategory, index) => (
                            <React.Fragment key={index}>
                                {subCategory && (
                                    <p className="bg-gray-50 text-gray-500 p-2 uppercase font-semibold tracking-wider text-xs rounded-md -mx-1 ">
                                        {subCategory}
                                    </p>
                                )}
                                <div className="relative grid gap-8 lg:grid-cols-1">
                                    {groupedBySubCategory[subCategory].map(
                                        item => (
                                            <Popover.Button
                                                as={Link}
                                                key={item.title}
                                                to={item.url}
                                                className="-m-3 flex  rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                            >
                                                <div className="flex h-10 w-10 mt-1 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12 bg-primary-100 rounded-lg">
                                                    <FontAwesomeIcon
                                                        icon={item.icon}
                                                        className="h-6 w-6 text-primary-700"
                                                    />
                                                </div>
                                                <div className="ml-4">
                                                    <p className="font-medium text-gray-700">
                                                        {item.title}
                                                    </p>
                                                    <p className="text-sm text-gray-400">
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </Popover.Button>
                                        )
                                    )}
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>
            </PopoverContainer>
        );
    }
}
export default ProductDropdown;
