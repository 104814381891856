import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'lib/utils';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const classes = {
    base:
        'whitespace-nowrap border shadow-sm focus:outline-none disabled:cursor-not-allowed duration-300 text-center relative justify-between',
    size: {
        xs: 'text-xs font-medium rounded px-2 py-1.5',
        sm: 'text-sm font-medium rounded-md px-3 py-2',
        base: 'text-sm font-medium rounded-md px-4 py-2',
        lg: 'text-base font-medium rounded-md px-5 py-3',
        xl: 'text-base font-medium rounded-md px-2 sm:px-6 py-3',
    },
    priority: {
        primary:
            'text-white border-transparent bg-primary-500 hover:bg-primary-600 disabled:bg-primary-200',
        pro:
            'text-white border-transparent bg-pro-500 hover:bg-pro-600 disabled:bg-pro-200',
        secondary:
            'text-primary-700 border-primary-100 bg-primary-50 hover:bg-primary-100 disabled:bg-primary-200 disabled:text-white',
        outlined:
            'text-primary-500 border-primary-500 bg-transparent hover:bg-primary-100 hover:border-primary-100 disabled:text-primary-200 disabled:border-primary-200 disabled:hover:bg-white',
        outlinedPro:
            'text-pro-500 border-pro-500 bg-transparent hover:bg-pro-100 hover:border-pro-100 disabled:text-pro-200 disabled:border-pro-200 disabled:hover:bg-white',
        outlinedRed:
            'text-red-500 border-red-500 bg-transparent hover:bg-red-100 hover:border-red-100 disabled:text-red-200 disabled:border-red-200 disabled:hover:bg-white',
        white:
            'bg-white hover:bg-gray-50 text-gray-600 disabled:bg-gray-50 disabled:text-gray-300',
        transparent:
            'border-transparent text-gray-700 hover:bg-gray-50 shadow-none',
        red:
            'border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:bg-red-200',
        custom:
            'text-white border-transparent bg-custom-500 hover:bg-custom-300 disabled:bg-custom-300',
        animatedPro:
            'text-white border-transparent bg-gradient-to-r from-pro-600 via-pro-200 to-pro-400 animate-gradient hover:from-pro-500 hover:via-pro-500 hover:to-pro-500 disabled:bg-pro-200 ',
    },
    leftIconSize: {
        xs: '-pl-0.5 pr-2 h-3 w-3',
        sm: '-pl-0.5 pr-2 h-4 w-4',
        base: '-pl-1 pr-2 h-5 w-5',
        lg: '-pl-1 pr-3 h-5 w-5',
        xl: '-pl-1 pr-3 h-5 w-5',
    },
    rightIconSize: {
        xs: 'pl-2 -pr-0.5 h-3 w-3',
        sm: 'pl-2 -pr-0.5 h-4 w-4',
        base: 'pl-2 -pr-1 h-5 w-5',
        lg: 'pl-3 -pr-1 h-5 w-5',
        xl: 'pl-3 -pr-1 h-5 w-5',
    },
    loadingIcon: {
        primary: 'text-white m-auto',
        pro: 'text-white m-auto',
        secondary: 'text-white m-auto',
        outlined: 'text-primary-200 m-auto',
        white: 'text-gray-400 m-auto',
        transparent: 'text-gray-700',
        red: 'text-white m-auto',
        custom: 'text-white m-auto',
    },
};

class Button extends Component {
    getLeftIcon = () => {
        const { size, leftIcon, leftIconClassName } = this.props;
        if (!leftIcon) return null;
        return (
            <FontAwesomeIcon
                icon={leftIcon}
                className={classNames(
                    classes.leftIconSize[size],
                    leftIconClassName
                )}
                aria-hidden="true"
            />
        );
    };

    getRightIcon = () => {
        const { size, rightIcon } = this.props;
        if (!rightIcon) return null;
        return (
            <FontAwesomeIcon
                icon={rightIcon}
                className={classNames(classes.rightIconSize[size])}
                aria-hidden="true"
            />
        );
    };

    render() {
        const {
            url,
            to,
            size,
            priority,
            disabled,
            onClick,
            onHover,
            leftIcon,
            rightIcon,
            scrollRef,
            scrollOffset,
            className,
            isLoading,
            target,
        } = this.props;

        const buttonClass = classNames(
            classes.base,
            classes.size[size],
            classes.priority[priority],
            leftIcon || rightIcon ? 'inline-flex items-center' : '',
            className
        );

        if (scrollRef)
            return (
                <ScrollLink
                    to={scrollRef}
                    spy={true}
                    smooth={true}
                    offset={scrollOffset || 0}
                    duration={500}
                    href={'#' + to}
                    className={buttonClass}
                >
                    {this.getLeftIcon()}
                    {this.props.children}
                    {this.getRightIcon()}
                </ScrollLink>
            );
        if (url) {
            return (
                <a
                    href={url}
                    className={buttonClass}
                    onMouseOver={onHover}
                    onClick={onClick}
                    target={target}
                >
                    {this.getLeftIcon()}
                    {this.props.children}
                    {this.getRightIcon()}
                </a>
            );
        }
        if (to) {
            return (
                <Link
                    to={to}
                    className={classNames(buttonClass, 'inline-block')}
                    onMouseOver={onHover}
                    onClick={onClick}
                >
                    {this.getLeftIcon()}
                    {this.props.children}
                    {this.getRightIcon()}
                </Link>
            );
        }
        return (
            <button
                type="button"
                className={buttonClass}
                disabled={disabled || isLoading}
                onMouseOver={onHover}
                onClick={onClick}
            >
                {isLoading ? (
                    <>
                        <FontAwesomeIcon
                            icon={light('circle-notch')}
                            spin
                            className={classNames(
                                'absolute inset-0 text-lg',
                                classes.loadingIcon[priority]
                            )}
                        />
                        <span
                            className={classNames(
                                'invisible',
                                leftIcon || rightIcon
                                    ? 'inline-flex items-center'
                                    : ''
                            )}
                        >
                            {this.getLeftIcon()}
                            {this.props.children}
                            {this.getRightIcon()}
                        </span>
                    </>
                ) : (
                    <>
                        {this.getLeftIcon()}
                        {this.props.children}
                        {this.getRightIcon()}
                    </>
                )}
            </button>
        );
    }
}

Button.defaultProps = {
    url: null,
    size: 'base',
    priority: 'primary',
    leftIcon: null,
    rightIcon: null,
    disabled: false,
    onClick: null,
    scrollRef: null,
    to: null,
    className: '',
    target: null,
};
export default Button;
