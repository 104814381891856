import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { classNames } from 'lib/utils';
import { compose } from 'recompose';
import withAuthUserContext from 'hoc/withAuthUserContext';
import { BUSINESS, FREE, SOLO } from 'constants/planType';
import { INDIVIDUAL } from 'constants/userType';
import * as ROUTES from 'constants/routingPaths';
import { ADMIN } from 'constants/accessManagement';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MobileMenuAuthenticated extends Component {
    render() {
        const {
            planType,
            userRole,
            userType,
            hasSubscription,
            parentPlanType,
        } = this.props.context;
        const { items, handleLogout } = this.props;

        const itemsCategories = [...new Set(items.map(item => item.category))];

        return (
            <Disclosure.Panel className="lg:hidden w-full bg-white z-20 shadow-md absolute">
                <>
                    <div className="pt-2 pb-3 space-y-1">
                        {planType === FREE && (
                            <Disclosure.Button
                                as={Link}
                                to={ROUTES.PRICING}
                                className={classNames(
                                    ' block pl-4 pr-4 pb-4 mb-3 text-sm font-medium  text-green-700 border-b border-gray-200 '
                                )}
                            >
                                <span
                                    className={classNames(
                                        'inline-flex items-center whitespace-nowrap'
                                    )}
                                >
                                    Passer au plan supérieur
                                    <FontAwesomeIcon
                                        icon={light('up-right-from-square')}
                                        className="pl-1.5 h-3 w-3"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Disclosure.Button>
                        )}
                        {userType === INDIVIDUAL && (
                            <>
                                <Disclosure.Button
                                    as={Link}
                                    to={ROUTES.VALUATION_ADDRESS}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                        window.location.pathname ===
                                            ROUTES.VALUATION_ADDRESS
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    Estimer un bien
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as={Link}
                                    to={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                        window.location.pathname ===
                                            ROUTES.AGENCIES_AND_AGENTS_DIRECTORY
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    Trouver un agent
                                </Disclosure.Button>
                            </>
                        )}
                        {itemsCategories.map((category, index) => (
                            <div key={index}>
                                {category && (
                                    <p className="bg-gray-50 py-2 px-3 text-sm w-full font-semibold text-gray-700">
                                        {category}
                                    </p>
                                )}
                                {items
                                    .filter(item => item.category === category)
                                    .map((item, i) => (
                                        <Disclosure.Button
                                            key={i}
                                            as="a"
                                            href={item.url}
                                            className={classNames(
                                                ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                                item.isHighlighted
                                                    ? 'bg-primary-50 border-primary-500 text-primary-700'
                                                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                            )}
                                        >
                                            {item.title}
                                        </Disclosure.Button>
                                    ))}
                            </div>
                        ))}
                    </div>
                    <div className="pt-4 pb-3 border-t border-gray-200">
                        <div>
                            <Disclosure.Button
                                as={Link}
                                to={ROUTES.PROFILE}
                                className={classNames(
                                    ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                    window.location.pathname.includes('/compte')
                                        ? 'bg-primary-50 border-primary-500 text-primary-700'
                                        : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                )}
                            >
                                Mon compte
                            </Disclosure.Button>
                            {userRole === ADMIN &&
                            userType !== INDIVIDUAL &&
                            (parentPlanType === null || hasSubscription) &&
                            (planType === BUSINESS || planType === SOLO) ? (
                                <Disclosure.Button
                                    as={Link}
                                    to={ROUTES.ACCESS_MANAGEMENT}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                        window.location.pathname.includes(
                                            '/parametres-acces'
                                        )
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    Gestion des utilisateurs
                                </Disclosure.Button>
                            ) : null}
                            <Disclosure.Button
                                as="a"
                                href="/#"
                                onClick={handleLogout}
                                className="block pl-3 pr-4 py-2 border-l-4 text-sm font-medium text-red-500 border-transparent hover:bg-red-50 hover:border-red-300 hover:text-red-700"
                            >
                                Déconnexion
                            </Disclosure.Button>
                        </div>
                    </div>
                </>
            </Disclosure.Panel>
        );
    }
}

export default compose(withAuthUserContext)(MobileMenuAuthenticated);
