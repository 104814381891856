export const FREE = 0;
export const BASIC = 1;
export const ESSENTIAL = 2;
export const SOLO = 3;
export const BUSINESS = 4;

export const FREE_LABEL = 'Découverte';
export const BASIC_LABEL = 'Basique';
export const SOLO_LABEL = 'Analyse';
export const ESSENTIAL_LABEL = 'Essentiel';
export const BUSINESS_LABEL = 'Business';
